
import { defineComponent, PropType } from 'vue'
import { IJobOfferResponse } from '@/models/jobBank/interfaces/jobOffer'
import { JobBankRouteNames } from '@/router/route-names'
import candidateCard from '@/views/jobBank/candidateCard.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    candidateCard
  },
  props: {
    isEditing: {
      type: Boolean
    },
    formOfferData: {
      required: true,
      type: Object as PropType<IJobOfferResponse>,
      default: null
    },
    city: {
      required: true,
      type: Object,
      default: null
    },
    company: {
      required: true,
      type: Object,
      default: null
    },
    modality: {
      required: true,
      type: String,
      default: null
    },
    hierarchy: {
      required: true,
      type: String,
      default: null
    },
    endDate: {
      required: true,
      type: Date,
      default: null
    },
    publishDate: {
      required: true,
      type: Date,
      default: null
    }
  },
  setup (props, { emit }) {
    let show = true
    const today = new Date()
    const wichState = (publishDate: any, endDate: any) => {
      let state
      if (endDate !== null && endDate !== undefined) { endDate = new Date(endDate) }
      if (publishDate !== null && publishDate !== undefined) { publishDate = new Date(publishDate) }
      switch (true) {
        case publishDate === null || publishDate === undefined :
          state = 'No publicada'
          break
        case endDate <= today:
          state = 'Finalizada'
          break
        case publishDate <= today:
          state = 'Publicada'
          show = true
          break
        default:
          state = 'No publicada'
          break
      }
      return state
    }

    const router = useRouter()
    const handleStatusChange = (publishDate: any, endDate: any) => {
      const state = wichState(publishDate, endDate)
      if (state === 'Publicada') {
        emit('handleStatusChange')
      } else {
        router.push(`/job-bank/job-offers/view/${props.formOfferData._id}/edit/publish`)
      }
    }

    const whenDate = (publishDate: any, endDate: any) => {
      let date
      const state = wichState(publishDate, endDate)
      if (endDate !== null && endDate !== undefined) { endDate = new Date(endDate) }
      if (publishDate !== null && publishDate !== undefined) { publishDate = new Date(publishDate) }
      switch (true) {
        case state === 'Publicada':
          date = `Publicada el ${publishDate.toLocaleDateString('es-AR')}`
          break
        case state === 'Finalizada':
          date = `Finalizó el ${endDate.toLocaleDateString('es-AR')}`
          break
        default:
          date = ''
          break
      }
      return date
    }

    return {
      JobBankRouteNames,
      wichState,
      show,
      handleStatusChange,
      whenDate
    }
  }
})
